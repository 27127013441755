import axios from 'axios'
import { encodeAppName } from '@/utils/general'

const recaptcha = {
  /**
   * @name verify
   * @method
   * @param {string} response_token this token is received from the recaptch execute method
   * @returns {Object} response object from cals api with google v3 repatcha payload (and score)
   */
  async verify (response_token = '') {
    if (!response_token) throw new Error('response_token required for recaptcha verification')

    // TODO: need to add recaptch URL as env variable; there is a prod version of the API
    const {
      data = {}
    } = await axios.post(`${process.env.VUE_APP_CALS_SERVICES}/gapi/recaptcha?app=westisburning`,
      {
        site: 'westisburning-recaptcha',
        response_token
      },
      {
        headers: {
          authorization: encodeAppName('westisburning')
        }
      }
    )

    return data
  }
}

export default recaptcha
