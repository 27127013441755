import recaptchaApi from '@/api/recaptcha'
import forOwn from 'lodash.forown'
import capitalize from 'lodash.capitalize'
import { withParams } from 'vuelidate'
import { resetObject } from '@/utils/general'

const touchMap = new WeakMap()
const delayTime = 800

// Example of custom validation with a custom "message" params that can be accessed by this.errorMessages()
// export let requiredTesting = (message) => withParams({
//   type: 'requiredTesting',
//   message
// }, value => value === 'Testing')

export default {
  watch: {
    '$v.$invalid': {
      handler(val) {
        this.appendFormAriaAlert(val)
      }
    }
  },

  data() {
    return {
      loading: false,
      error: null,
      success: false,
      recaptchaScore: 0.0,
      recaptchaThreshhold: 0.5,
      possibleRobot: false
    }
  },

  computed: {
    errorCollection() {
      // TODO: create an accessible list of errors
    }
  },

  methods: {
    errorMessages(fieldName, label, override) {
      let field = this.getField(fieldName)
      let readableField = label || capitalize(fieldName)
      let errors = []

      if (field.$error) {
        forOwn(field.$params, (value, key) => {
          if (!field[key] /* this checks if validation rule has passed */) {
            (override)
              ? errors.push(override) // display a completely custom error message
              : errors.push(this.validationMessages({ type: key, params: field.$params[key], fieldName: readableField })) // display just a custom field label in the error message
          }
        })
      }

      return errors
    },

    validationMessages({ type, params, fieldName }) {
      let messages = {
        minLength: `${fieldName} can't be fewer than ${params.min} characters.`,
        maxLength: `${fieldName} can't be longer than ${params.max} characters.`,
        required: `${fieldName} field is required`,
        email: 'Not a valid email address',
        url: 'Not a valid URL'
      }

      return messages[type]
    },

    getField(fieldName) {
      const field = this.$v.form[fieldName] || this.$v[fieldName]

      if (!field) throw new Error(`Field ${fieldName} does not exist`)

      return field
    },
    
    async formSubmit(submitFn = () => {}) {
      if (this.$v && this.$v.$invalid) {
        this.$v.$touch()
        this.appendFormAriaAlert(false)
        this.handleInvalidForm()
        return false
      }

      try {
        this.loading = true

        if (this.$recaptchaLoaded && await this.handleRecaptcha() <= this.recaptchaThreshhold) {
          this.possibleRobot = true
        }

        await submitFn()
      } finally {
        // if a form submission triggers a route change, typically this will "beat" the transition
        // which means the loading animation will end before the route changes
        // sometimes this looks weird :)
        this.loading = false
      }
    },

    handleInvalidForm () {
      // override in your component
    },

    async handleRecaptcha () {
      const {
        _componentTag = 'noAction'
      } = this.$options || {}
      const action = _componentTag.replace(/-/gi, '_')
      
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha(action)
      const { score } = await recaptchaApi.verify(token)

      console.log(`Recaptcha threshhold: ${this.recaptchaThreshhold}`)
      console.log(`Recaptcha score: ${score}`)
      this.recaptchaScore = score

      return score
    },

    resetForm() {
      this.$v.$reset()

      if (this.form) {
        this.form = resetObject(this.form)
      }
    },

    appendFormAriaAlert(val) {
      let alert = document.createElement('p')
      alert.setAttribute('aria-live', 'assertive')
      alert.className = 'hidden-aria-alert'

      if (val) {
        alert.innerHTML = this.$t("aria.FormInvalid")
        this.$el.appendChild(alert)
      } else {
        alert.innerHTML = this.$t("aria.FormValid")
        this.$el.appendChild(alert)
      }
    },

    delayTouch($v) {
      this.$v.$reset()

      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, delayTime))
    }
  }
}
